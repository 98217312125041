/* Hotel Detail Page Styles */
.hotel-detail {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
}

.hotel-name {
    font-size: 24px;
    margin: 0;
    padding: 10px 0;
}

.hotel-image img {
    max-width: 100%;
    max-height: 300px;
}

.booking-id {
    font-weight: bold;
    margin: 10px 0;
}

.description {
    margin: 20px 0;
}

.instructions h2,
pricing-details h2 {
    font-size: 18px;
    margin: 10px 0;
}

.instructions ul {
    list-style-type: disc;
    margin-left: 20px;
}

/* Responsive CSS */
@media (max-width: 768px) {
    .hotel-image img {
        max-height: 200px;
    }

    .booking-id,
    .description,
    .instructions,
    .pricing-details {
        margin: 10px 0;
    }
}