.grid-header{
    background-color: black;
    color: beige;
}
@media only screen and (max-width: 600px) {
    .modal-class {
      width: 70%;
    }
}
@media only screen and (min-width: 600px) {
    .modal-class {
      width: 50%;
    }
}
